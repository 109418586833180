import React from "react"
import { goto } from "../../utils/links"
import { Separator } from "../course-ui-components/SeparatorPadding"
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export const CourseHeaderMain = ({theme, info, data, actionButtons}) => {


  return (
    <div>
      <div className="row middle-xs">
        <div className="col-xs-12 col-sm-8">
          <h1 className="text-header-main-title" dangerouslySetInnerHTML={{ __html: info.title}} />
          <h2 className="text-header-main-subtitle" dangerouslySetInnerHTML={{ __html: info.subtitle}} />
          <Separator size="md" />
        </div>

        <div className="col-xs-12 col-sm-4" style={{padding: 0}}>
          <div
            className="right"
          >
            <img
              width="100%"
              src={data[info.image].childImageSharp.fluid.src}
              alt={data[info.image].name}/>
          </div>
        </div>

        <br/>
      </div>

      <br/>
      <div className="center">
        {
          actionButtons.visible ?
            <button
              style={{backgroundColor: theme.buttonColor}}
              className="button button1"
              disabled={!actionButtons.enabled}
              onClick={() => goto(actionButtons.buyLink, actionButtons.target, actionButtons.scrollToID)}
            >
              <FontAwesomeIcon icon={faArrowCircleRight} fixedWidth={true} style={{marginRight: 10}} />
              {actionButtons.buyText}
            </button> : null
        }

      </div>
      <br/>

    </div>
  )
}










