import React from 'react';
import css from './CourseInstructorProfile.module.css';
import { graphql, StaticQuery } from "gatsby"
import { Separator } from "../course-ui-components/SeparatorPadding"
import { goto } from "../../utils/links"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons"


export class CourseInstructorProfile extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            profile: file(relativePath: { eq: "courses/_general/profile/codemotion-2019-circle.png" }) { ...thumb },
            loghi_gde_mvp: file(relativePath: { eq: "courses/_general/profile/loghi_gde_mvp.png" }) { ...thumb },
            
          }
        `}
        render={data => <Component {...this.props} data={data}  /> }
      />
    )
  }
}
/*angular_badge: file(relativePath: { eq: "courses/_general/profile/2019-GDE-Angular-Badge.png" }) { ...thumb },
web_badge: file(relativePath: { eq: "courses/_general/profile/2019-GDE-Web-Technologies-Badge.png" }) { ...thumb },*/

export const Component = props => (

  <div className={css.wrapper}>
    <div className="text-box-title center">
      <span className="light">ISTRUTTORE: </span>
      <br className="show-on-xs"/>
      <span className="bold">FABIO BIONDI</span>
    </div>

    <br/>

    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-5">
        <img
          width="100%"
          src={props.data.profile.childImageSharp.fluid.src}
          alt={props.data.profile.name}/>

        <br/>
        <br/>

        <div className="center">
          <button
            style={{backgroundColor: props.theme && props.theme.buttonColor}}
            className="button button1"
            onClick={() => goto('https://www.fabiobiondi.io', '_blank', null)}
          >
            <FontAwesomeIcon icon={faArrowCircleRight} fixedWidth={true} style={{marginRight: 10}} />
            Visit fabiobiondi.io
          </button>
        </div>
        <br/>
        <br/>
      </div>

      <div className="col-xs-12 col-md-7">

        <div className="text-paragraph-title">
          TRAINER, SPEAKER, DEVELOPER
        </div>
        <Separator size="md"/>
        <div>
{/*
          Fabio is both a community leader and a frequent contributor in many Italian front-end usergroups (Angular, React, Javascript, ...). He's a <strong>Google Developer Expert</strong> in Web Technologies and Angular with more than 15 years of experience in developing enterprise level applications, interactive experiences and UI components in several languages and web technologies.
          <br/><br/>
          Currently, he holds meetups, events and training courses all over Italy and he's speaker in a lot of conferences such as CodeMotion, WebAppConf, Angular Day and more...
          <br/><br/>
          His particular specialism is front-end applications and related aspects, both visuals and architectural. He daily uses cutting-edge libraries and frameworks offering training, development, code review and consulting on Angular, React, Typescript/ES6, Redux, NGRX, RxJS, Firebase, D3.js, CreateJS and several other web technologies.
*/}

          Mi chiamo <strong>Fabio Biondi</strong>, sono <strong>Microsoft MVP</strong> e <strong>Google Developer Expert</strong> in Angular e Web Technologies,
          e ho più di 15 anni di esperienza nella creazione di applicazioni web enterprise.

          <br/>
          <br/>

          <strong>Sviluppatore, Istruttore</strong> e <strong>Speaker</strong> in numerosi eventi. Ho fondato e gestisco diverse community italiane che si occupano di Javascript, Angular, React e Opportunità per gli sviluppatori (più di 30000 membri).


          <br/>
          <br/>
          Attualmente mi occupo di formazione e mentoring su <strong>Javascript / Typescript, Angular, React, Redux, NGRX, RxJS, Next</strong> e, recentemente, <strong>Lit e Web Components</strong>.
          <br/>
          Mi occupo, inoltre, di creazione contenuti tecnici su YouTube (in italiano e in inglese) e sono partner su Twitch in cui mi trovate spesso in streaming (con ospiti, sessioni di formazione, live coding e studio di nuove tecnologie).

          <br/>
          {/*Nell'ultimo anno mi sono, inoltre, avvicinato molto al mondo Microsoft: Azure, creazione addIn per Microsoft Office 365 in React, utilizzo di Graph API in SPA, Microsoft Cognitive Services (Computer Vision, Translator, Text and Video Analytics) e molto altro*/}

          <strong>CERTIFICATIONI & AWARDS</strong>
          <br/>
          <br/>
          <li>Dal 2019: <strong>Microsoft MVP</strong></li>
          <li>Dal 2018: <strong>Google Developer Expert</strong> in Web Technologies e Angular</li>
          <li>2004-2011: <strong>Adobe Certified Expert & Instructor</strong> (Flex, AIR, Flash).</li>

          <br/>

          <div className="row">
            <div className="col-xs-12 col-sm-8 col-md-8">
              <img
                width={'100%'}
                style={{ marginRight: 20}}
                src={props.data.loghi_gde_mvp.childImageSharp.fluid.src}
                alt="Awards Google & Microsoft"
              />
            </div>
          </div>

{/*

          <div className="row">
            <div className="col-xs-6 col-sm-3 col-md-3">
              <img
                width={'100%'}
                style={{ marginRight: 20}}
                src={props.data.loghi_gde_mvp.childImageSharp.fluid.src}
                alt={props.data.angular_badge.name}/>
            </div>
            <div className="col-xs-6 col-sm-3 col-md-3">
              <img
                width={'100%'}
                src={props.data.web_badge.childImageSharp.fluid.src}
                alt={props.data.web_badge.name}/>
            </div>
          </div>
*/}



        </div>
      </div>

    </div>
  </div>
)
