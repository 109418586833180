import React from 'react';
import { Separator } from "../course-ui-components/SeparatorPadding"
import Collapsible from "../../shared/Collapsable"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowCircleRight} from "@fortawesome/free-solid-svg-icons"


export const Title = ({value, theme}) => {
  return (
    <div style={{cursor: 'pointer'}}>
      <FontAwesomeIcon
        icon={faArrowCircleRight}
        fixedWidth={true}
        style={{width: 20, fontSize: 20, color: theme.iconColor2}} /> <strong>{value}</strong>
    </div>
  )
}

export const CourseFaq = ({faq, theme}) => {
  return (
    <div>
      <div className="text-box-title center">
        FAQ
      </div>
      <Separator size="xl"/>

      {
        faq.items.map((item, index) => {
          return (
            <div  key={index} >
              <Separator size="md" />

              <Collapsible trigger={<Title value={item.title} theme={theme}/> } transitionTime={200}>

                <div className="row left">
                  <div dangerouslySetInnerHTML={{ __html: item.desc}} className="col-xs"/>
                </div>
              </Collapsible>
            </div>

          )
        })
      }

    </div>
  )
};

