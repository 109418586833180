import React from 'react';
import { Separator } from "../course-ui-components/SeparatorPadding"

export const CourseMode = (props) => (
  <div>
    <div className="text-box-title center">
      MODALITÀ LEZIONI
    </div>

    <Separator size="xl"/>

    <div className="row">
      <div className="col-xs-12 col-sm" style={{ paddingRight: 10, paddingTop: 20}}>
        <Item {...props} item={props.mode.colLeft} />
      </div>
      {props.mode.colCenter ? <div className="col-xs-12 col-sm" style={{ paddingRight: 10, paddingTop: 20}}>
        <Item {...props} item={props.mode.colCenter} />
      </div> : null }
      <div className="col-xs-12 col-sm" style={{ paddingTop: 20}}>
        <Item {...props} item={props.mode.colRight} />
      </div>
    </div>
  </div>
)


const Item = props => (
  <div>
    <div className="text-paragraph-title">{props.item.title}</div>
    <img
      width="100%"
      src={props.data[props.item.img].childImageSharp.fluid.src}
      alt={props.data[props.item.img].name }/>
    <div >{props.item.text}</div>
  </div>
)
