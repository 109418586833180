export const SHARED_COURSE_CONTENT = {
  faq: {
    items: [

      { title: 'A chi è rivolto?', desc: '<strong>Principalmente a team e società</strong> che intendono organizzare corsi di formazione per un ristretto gruppo di partecipanti (da 3 a 30 persone).<br />Tuttavia, organizzo spesso corsi ed eventi aperti al pubblico in cui chiunque può partecipare (freelance, appassionati, dipendenti). Ti invito a seguirmi sui vari canali social o di iscriverti alla newsletter per rimanere aggiornato sulle iniziative' },
      { title: 'Non sono sicuro che questo corso sia adatto al mio team. Puoi aiutarmi ad orientarmi?', desc: 'Assolutamente sì. Utilizza il <a href="../forms/contact-form/?subject=1">form di contatto</a> per organizzare una riunione Skype / telefono'},
      { title: 'Posso modificare il programma e creare un corso personalizzato ?', desc: 'Sì. Puoi scegliere gli argomenti che preferisci da questo e altri corsi e possiamo stilare insieme un programma personalizzato sulla base dei vostri obiettivi e del background dei partecipanti. Per i corsi più avanzati, solitamente, organizzo una skype call con i partecipanti in modo da valutare il grado di preparazione attraverso un\'intervista e/o una sessione di code review  (gratuita, della durata di 30/60 minuti)'},

      { title: 'Dove può essere erogato il corso?', desc: `
• In Friuli, Ronchi dei Legionari (Gorizia) - Max 14 posti con seduta e tavolo oppure 80 posti solo seduta.<br />
• On-Site, presso la vostra sede, in tutta Italia<br />
`},
      /*{ title: 'Quando sarà erogato il corso?', desc: 'Spesso organizzo eventi e corsi aperti al pubblico ( ti invito a seguirmi sui vari canali social o di iscriverti alla newsletter) ma la maggior parte dei corsi sono erogati presso la sede della società che lo richiede, in date stabilite di comune accordo' },*/
      { title: 'Quanto costa?', desc: 'Richiedi le mie tariffe attraverso il <a href="../forms/contact-form/?subject=1">form di contatto</a> specificando indirizzo in cui erogare il corso, il periodo e la durata'},
      { title: 'C\'è un quantitativo minimo di giornate da acquistare?', desc: `
 Almeno 2 giornate se la durata del viaggio (da Trieste, la provincia in cui risiedo) è inferiore alle 4 ore. 
 <br />Tre giornate, invece, nel caso di una durata superiore.  
        `},

      /*{ title: 'Qual è la quantità minima di giornate necessarie per organizzare un corso?', desc: `
        Per ammortizzare il costo della trasferta, soprattutto i tempi di viaggio, la quantità di giornate necessarie al fine di organizzare un corso di formazione è strettamente legata alla regione in cui sarà erogato:<br />
        • se il corso è organizzato in <strong>Friuli</strong>, posso erogare corsi anche di <strong>una sola giornata</strong>;<br />
        • in <strong>Veneto</strong>, le giornate devono essere almeno <strong>due</strong>;<br />
        • in tutte le <strong>altre regioni</strong>, il minimo è di <strong>tre giornate</strong>, quindi è spesso necessario acquistare più di un corso affinché si possa organizzare;<br />
        • <strong>Eccezione 1</strong>: se la durata del viaggio (per tratta, da Trieste Airport) è comunque inferiore alle 4 ore, ad esempio se la sede del corso è situata in prossimità di stazioni ferroviarie o località facilmente raggiungibili con i mezzi pubblici (treno / aereo);<br /> 
        • <strong>Eccezione 2</strong>: se la durata del viaggio (per tratta, da Trieste Airport) ha una durata superiore alle 6. In tal caso è possibile valutare corsi di una durata maggiore o valutare soluzioni alternative. <br /> 
        `},*/
      { title: 'Il corso prevede esercitazioni per gli studenti?', desc: 'La scelta è vostra. È possibile effettuare 1 o 2 ore di esercitazioni al giorno oppure lasciare spazio agli argomenti del corso ed approfondimenti'},
      { title: 'Devo portare il mio laptop?', desc: 'Lo consiglio ma non è indispensabile. È necessario solo se intendete configurare l\'ambiente di lavoro o effettuare esercitazioni. Un blocco appunti e una penna sono più che sufficienti dato che, dopo il corso, avrete comunque accesso al materiale tramite un\'area riservata (slide, esercitazioni, source code) '},

    ]
  },
}
