/* eslint-disable */

import React, { useEffect, useState } from "react"
import { TweenLite, ScrollToPlugin, Power2 } from "gsap/all";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronUp } from "@fortawesome/free-solid-svg-icons"
const plugins = [ ScrollToPlugin ];

import css from './ArrowScrollToTop.module.css';
import cn from 'classnames';

const scrollToTop = () => {
  TweenLite.to(window, 0.9, { scrollTo: 0, ease: Power2.easeOut})
}

export const ArrowScrollToTop = ({color}) => {
  const [isArrowVisible, setArrowVisible] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', showContent)

    return () => {
      window.removeEventListener('scroll', showContent)
    }
  }, [])

  const showContent = () => {
    if (document.body.scrollTop > 600 || document.documentElement.scrollTop > 600) {
      setArrowVisible(true)
    } else {
      setArrowVisible(false)
    }
  }

  return <div
    onClick={scrollToTop}
    style={{backgroundColor: color}}
    className={cn('center', css.wrapper, {[css.hide]: !isArrowVisible })}
  >
    <FontAwesomeIcon icon={faChevronUp} fixedWidth={true} style={{width: 30, fontSize: 30}} />
  </div>
};

