import { Carousel } from "react-responsive-carousel"
import React from "react"

export const CarouselCarouselReview = (props) => (
  <div style={{maxWidth: 700, margin: '0 auto'}} >
    <div className="text-box-title center">
        <span className="light">RECENSIONI</span><span className="bold hide-on-mobile">UTENTI</span>
    </div>
    <br/>
    <Carousel
      infiniteLoop
      emulateTouch
      autoPlay
      showStatus={false}
      stopOnHover
      interval={5000}
      showArrows={true}
      dynamicHeight={false}
      showThumbs={false}>
      {
        props.items.map(item => {
          return (
            <div key={item}>
              <img
                src={props.data[item.img].childImageSharp.fluid.src}
                alt={props.data[item.img].name} />
            </div>
          )
        })
      }
    </Carousel>
  </div>
)
