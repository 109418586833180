import React from "react"
import {CoursePageFull} from "../components/course-pages/CoursePageFull"
import { graphql, StaticQuery } from "gatsby"
import { faChartBar, faClock, faCode, faUser } from "@fortawesome/free-solid-svg-icons"
import { SHARED_COURSE_CONTENT } from "../components/shared/courses-text"

export default class CorsoAngularForms extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            angular_forms: file(relativePath: { eq: "courses/courses-logos/angular-forms.png" }) { ...thumb },

            carouselIntro1: file(relativePath: { eq: "courses/courses-carousel/angular-core-concepts-course-diagram.png" }) { ...imgTrainingBig },

             mode1: file(relativePath: { eq: "courses/courses-mode-images/angular-forms-mode1.png" }) { ...imgMd },
            mode2: file(relativePath: { eq: "courses/courses-mode-images/angular-forms-mode2.png" }) { ...imgMd },
            mode3: file(relativePath: { eq: "courses/courses-mode-images/angular-forms-mode3.png" }) { ...imgMd },
            pricing_bg: file(relativePath: { eq: "courses/angular-ngrx/pricing/bg_price_1.png" }) { ...thumb },

          }
        `}
        render={data => <CoursePageFull {...this.props}  data={data} course={COURSE_INFO}/> }
      />
    )
  }
}

export const COURSE_INFO = {
  theme: {
    separatorHeaderColor1: '#dd0031',
    separatorHeaderColor2: '#c3002f',
    separatorColor1: '#dd0031',
    separatorColor2: '#4a90e2',
    buttonColor: '#dd0031',
    panelBackground1: '#E66161',
    iconColor1: '#666',       // summary
    iconColor2: '#dd0031',    // faq
    footerVisible: true,
    navbarVisible: true,
  },
  header: {
    titleSEO: 'Un corso dedicato ai Reactive Forms in Angular',
    title: 'ANGULAR <strong>REACTIVE FORMS</strong>',
    shortTitle: 'ANGULAR <br /><strong>REACTIVE FORMS</strong>',
    subtitle: 'Tutto ciò che devi sapere sui Reactive Forms, uno strumento fantastico per la creazione di form complessi, dinamici e reattivi',
    image: 'angular_forms',
    difficulty: 'Avanzato',
    duration: '8 ore'
  },
  description: [
    { title: 'INTRODUZIONE', text: 'I form rappresentano buona parte delle funzionalità incluse in applicazioni web enterprise e corporate (B2B, banche, fintech, scuole, PA, assicurazioni ma anche in CRM, CMS…) e sono, spesso, la parte più complessa, lunga e noiosa da gestire. <br>Ma dopo l’uscita di AngularJS, e poi Angular (2+), le cose sono cambiate 😎. <br /><br>Angular è il framework front-end che integra il maggior numero di funzionalità per la gestione di form, dividendoli in due tipologie: template-driven form , totalmente basati sui template HTML, e i Reactive Forms, basati sul concetto di Observable tramite l’utilizzo della libreria RxJS. <br />In questo corso intensivo ci focalizzeremo quasi totalmente sui Reactive Forms, in quanto offrono una flessibilità decisamente superiore rispetto ai template-driven forms, ed esploreremo diverse tecniche per sfruttarli al meglio con esempi pratici e casi d’uso reali: dalle fondamenta fino ai casi più complessi.' },
    /*    { title: 'DURATA', text: 'Il corso ha una durata variabile tra le 16 e le 24 ore, quindi 2 o 3 giornate: <br /><br />• 16 ore: il corso sarà intensivo, con un ritmo molto veloce e ci concentreremo sui contenuti <br/>• 24 ore: ritmo più lento, argomenti affrontati in modo più approfondito, gli studenti potrano eseguire alcune esercitazioni e configurare l\'ambiente di lavoro'},*/
    { title: 'OBIETTIVO DEL CORSO', text: 'Questo corso intensivo si rivolge a tutti gli sviluppatori che già utilizzano Angular o che desiderano esplorare le potenzialità offerte dal framework per quanto concerne la gestione di form. Al termine del corso sarai in grado di utilizzare la maggior parte delle tecniche disponibili in Angular per la creazione e gestione di form di media-alta complessità.'},
    { title: 'REQUISITI', text: 'Per trarre il massimo beneficio da questo corso è necessario, o quanto meno fortemente consigliato, conoscere i concetti fondamentali alla base di Angular o aver partecipato al corso <a href="../corso-angular-core-concepts/">Angular Core Concepts</a>: utilizzo di direttive ngIf, ngFor, ngSwitch; avere basi sul concetto di "service" e dependency injection; comunicazione con il server tramite HttpClient e realizzazione di componenti custom.' },
  ],
  metadata: [

  ],
  carousels: {
    main: null,
    reviews: null
  },
  summary: [
    { icon: faClock, label: 'Durata:', value: '8 ore' },
    { icon: faChartBar, label: 'Livello:', value: 'Avanzato' },
    { icon: faCode, label: 'Versione Angular:', value: '14.x' },
    { icon: faCode, label: 'Versione Typescript:', value: '4.x' },
    { icon: faUser, label: 'Tipologia:', value: 'in aula o da remoto' },
  ],
  exampleLesson: {
    enabled: true,
    title: 'ESEMPIO LEZIONE',
    desc: 'In questo video:',
    video1: 'LQ2MlD9yDkg',
    bgColor: '#c3002f',
    items: [
      { text: 'Utilizzo avanzato di Angular Reactive Forms'},
      { text: 'Creazione custom form "Rate" control'},
      { text: 'Implementazione di ControlValueAccessor'},
    ]
  },
  mode: {
    colLeft: {
      img: 'mode1',
      title: 'TEORIA',
      text: 'Approfondimento su API, tecniche e funzionalità del framework. Decine di slide, diagrammi e materiale esclusivo incluso nel corso'

    },
    colCenter: {
      img: 'mode2',
      title: 'LIVE CODING',
      text: 'La maggior parte degli argomenti sono esposti tramite sessioni di live coding, affrontando scenari reali e creando codice riutilizzabile',

    },
    colRight: {
      img: 'mode3',
      title: 'SOURCE CODE',
      text: 'Al termine del corso avrete a disposizione moltisissi esempi e snippet di codice riutilizzabili in applicazioni reali',
    }
  },
  program: {
    colLeft: [

      { type: 'title', value: 'Introduzione ai Reactive Forms & RXJS' },
      { value: 'Relazione tra Forms & RXJS' },
      { value: 'Introduzione sui Reactive Forms' },
      { value: 'Template driven-forms vs Reactive forms: comparativa tra le tue tipologie di form anche tramite l’ausilio di unit test' },
      { value: 'Forms e Reactive Programming' },
      { value: 'AbstractControl e FormControl' },
      { value: 'Creare reactive form con FormBuilder' },
      { value: 'Manipolare Forms Group e FormControl con RxJS' },
      { value: 'Gli operatori RxJS più utili: scenari e casi d’uso reali' },
      { value: 'Custom FormControl validators: differenti tecniche per la creazione di custom validator per i singoli campi (controls)' },
      { value: 'Custom FormControl validators con gestione parametri' },

    ],
    colRight: [
      { type: 'title', value: 'Mastering Reactive Form' },
      { value: 'Custom FormGroup validators: creazione di validatori di gruppo in cui la validazione di un campo è legata al contenuto di un altro campo' },
      { value: 'Custom async form validators: validatori asincroni tramite l’utilizzo di REST API e gestione dello stato di pending' },
      { value: 'Impostare e cambiare validatori a runtime' },
      { value: 'FormGroup nidificati: creare e validare gruppi di controlli' },
      { value: 'Validazioni parziali di un form: validare diversi FormGroup all’interno di un unico form' },
      { value: 'Creazione form multi step' },
      { value: 'Organizzare un form in differenti componenti indipendenti utilizzando differenti tecniche' },
      { value: 'FormArray: validare array di form, rimuovere e aggiungere elementi' },
      { value: 'Creare form a runtime tramite l’utilizzo di API low-level del framework' },
      { value: 'Creare componenti custom FormControl con ControlValueAccessor: creare custom form control e integrazione con il sistema di validazione del framework. Alcuni esempi tipici sono Color Picker, Calendar e simili' },
      { value: 'Generazione di form dinamici a partire da un formato JSON' },
    ],
  },
  pricing: {
    price: null,
    priceOffer: null,
    priceOfferDescription: null,
    items: [
      { label: 'Corso di formazione on-site', extra: '8 ore'},
      { label: 'Slides ed Esempi', extra: 'Accesso Area Studenti'},
      { label: 'Codice Sorgente', extra: 'Incluso'},
      { label: 'Attestato di partecipazione', extra: 'PDF formato A4'},
    ],
    button: {
      enabled: true,
      visible: true,
      buyLink: '/forms/contact-form?subject=1',
      buyText: 'RICHIEDI PREVENTIVO',
      target: '_self',
      scrollToID: ''
    },
    bg: null,
  },
  newsletter: {
    enabled: true,
    campaignFormUrl: 'https://fabiobiondi.us16.list-manage.com/subscribe/post?u=2478e04b9171b207e77c6cbc0&amp;id=85573d8b21',
  },
  actionButtons: {
    main: {
      enabled: true,
      visible: true,
      buyLink: null,
      buyText: 'RICHIEDI PREVENTIVO',
      target: '_scrollTo',
      scrollToID: '#pricingPanel'

    }
  },
  faq: SHARED_COURSE_CONTENT.faq,
  videos: {
    teaser1: 'PGa6gC8_GJg',
    teaser1Text: `
Quale sviluppatore non odia i form?
<br /><br />
Soprattutto in ambito enterprise, ma anche in CRM, CMS, applicazioni per PA
sono spesso molto complessi e noiosi da realizzare
ma grazie ad Angular vi assicurò che diventerà un'attività quasi piacevole.
<br /><br />
Angular ci mette a disposizione , infatti, due tipologie di form, i template driven form e i reactive forms.
In questo corso, quasi interamente dedicato ai Reactive Forms, analizzeremo, quindi, moltissimi scenari e casi d'uso,
e utilizzeremo buona parte delle API del framework tramite sessioni di live coding con decine di esempi pratici
e snippet.
<br /><br />
Ma dopo questa breve introduzione preferisco descrivervi alcuni degli esempi realizzati durante il corso:
<br /><br />
• Partirò dalle basi descrivendo FormControl, FormGroup e FormBuilder
<br /><br />
• Utilizzerò gli operatori RxJS per la gestione dello stato del form
<br /><br />
• Creeremo Custom Validators, gestiremo gli errori e in questo esempio in particolare sostituisco a runtime un validatore, da codice fiscale a partita iva
<br /><br />
• Suddivideremo il form in FormGroup per validare porzioni di form
<br /><br />
• Utilizzeremo i FormArray per la gestione di elenchi di form
<br /><br />
• Creeremo FormGroup Validators, utili quando la validazione di un controllo dipende dal valore di un altro
<br /><br />
• In questo esempio creiamo un color picker che si integra e invalida il form grazie all'implementazione di ControlValueAccessor.
<br /><br />
• Gestiremo validatori asincroni tramite REST API
<br /><br />
• Avremo modo di mixare validatori sincroni e asincroni
<br /><br />
• Suddivideremo un form in diversi componenti separati utilizzando 3 differenti tecniche
<br /><br />
• Creeremo form multi-step organizzando il codice in componenti che implementano ControlValueAccessor
<br /><br />
• Molto interessante anche la creazione di form a runtime generato tramite il caricamento un file JSON

<br /><br />
Moltissimo del codice sviluppato sarà compatibile anche con i template driven forms,
di conseguenza riuscirete a riutilizzare buona parte dei custom form component che andremo a realizzare

    `
  },
}


