import '../../styles/flexboxgrid.css';
import '../../styles/carousel-responsive.css';

import React from "react"
import { graphql } from "gatsby"
import YouTube from "react-youtube"

import Layout from "../layout"
import SEO from "../seo"

import { CourseSummaryTable } from "../course/course-summary-table/CourseSummaryTable"
import { CourseCarousel } from "../course/course-carousel-intro/CourseCarouselIntro"
import { CouseSecondaryDescriptionBox } from "../course/course-description/CouseSecondaryDescriptionBox"
import { SeparatorWave } from "../course/separator-wave/SeparatorWave"
import { CourseProgram } from "../course/course-program/CourseProgram"
import { CourseMode } from "../course/course-mode/CourseMode"
import { CourseLessonExample } from "../course/course-lesson-example/CourseLessonExample"
import { CourseInstructorProfile } from "../course/course-instructor-profile/CourseInstructorProfile"
import { CarouselCarouselReview } from "../course/course-carousel-reviews/CourseCarouselReview"
import { CoursePriceSingle } from "../course/course-price/CoursePriceSingle"
import { CourseDescription } from "../course/course-description/CourseDescription"
import { CourseHeaderMain } from "../course/couse-header-main/CourseHeaderMain"
import { Footer } from "../shared/Footer"
import { Separator } from "../course/course-ui-components/SeparatorPadding"
import Collapsible from "../shared/Collapsable"
import { CourseFaq } from "../course/course-faq/CourseFaq"
import { ArrowScrollToTop } from "../shared/ArrowScrollToTop"
import { CourseNewsletterLink } from "../course/course-newsletter/CourseNewsletterLink"
import { CourseInstructorProfileGiorgioBoa } from "../course/course-instructor-profile/CourseInstructorProfileGiorgioBoa"

export const imgTrainingBig = graphql`
  fragment imgTrainingBig on File {
    name,
    childImageSharp {
      fluid(maxWidth: 1400) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`


export const imgMd = graphql`
  fragment imgMd on File {
    name,
    childImageSharp {
      fluid(maxWidth: 800) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`


export const thumb = graphql`
  fragment thumb on File {
    name,
    childImageSharp {
      fluid(maxWidth: 400) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const CoursePageFull = (props) => {
  const opts = {
    width: '100%',
    height: '100%',
    playerVars: { // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
      rel: 0,
      showinfo: 0,
      modestbranding: 1
    }
  };


  const onReady = (event) => {
    // access to player in all event handlers via event.target
    /*event.target.pauseVideo();*/
  }

  const {
    course: { actionButtons, carousels, faq, header, newsletter, pricing, theme, videos }
  }= props;

  return <Layout navbarVisible={theme.navbarVisible
  }>
    <SEO title={header.titleSEO} description={header.titleSEO} />
    <SeparatorWave bg1={theme.separatorHeaderColor1} bg2={theme.separatorHeaderColor2} />

    <Separator size="xl"/>

    <div className="container-big">
      <CourseHeaderMain
        {...props}
        theme={theme}
        info={header}
        actionButtons={actionButtons.main}
      />
    </div>

    <Separator size="md"/>


    <div className="container-big">
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-7">
          <CourseDescription description={props.course.description}/>
        </div>

        <div className="col-xs-12 col-sm-6 col-md-5">
          <br/>
          <CourseSummaryTable theme={theme} data={props.course.summary} />
        </div>
      </div>
    </div>

    <div className="container-big">
      <br/>
      {
        carousels.main ?
          <CourseCarousel
            {...props}
            items={carousels.main}
          /> : null
      }
    </div>



    {
      props.course.description2 ?
        <div className="container-big">
          <div className="row">
            <div className="col-xs-12 col-md-8">
              <CouseSecondaryDescriptionBox data={props.course.description2}/>
            </div>
          </div>
        </div> : null
    }

    <div className="container-big">
      <br/>
      {
        carousels.description2 ?
          <CourseCarousel
            {...props}
            items={carousels.description2}
          /> : null
      }
    </div>


    {
      props.course.metadata ?
        <div className="container-big">
          <div className="row">
            <div className="col-xs-12 col-md-8">
              <CouseSecondaryDescriptionBox data={props.course.metadata} />
            </div>
          </div>
        </div> : null
    }


    <Separator size="lg"/>


    <div className="container-big">
      {
        videos.teaser1 ?
          <div>
            <div className="videoWrapper">
              <YouTube containerClassName="youtube-video" videoId={videos.teaser1} opts={opts} onReady={onReady}/>
            </div>

            <Separator size="lg" />

            {
              videos.teaser1Text ?
                <div className="center">

                  <Collapsible trigger="TRASCRIZIONE DEL VIDEO" triggerClassName="video_read_text" triggerOpenedClassName="video_read_text" >
                    <Separator size="md" />

                    <div className="row left">

                      <div dangerouslySetInnerHTML={{ __html: videos.teaser1Text }} className="col-xs"/>
                    </div>
                  </Collapsible>
                </div> : null
            }
          </div> : null
      }

    </div>


      <SeparatorWave bg1={theme.separatorColor1} bg2={theme.separatorColor2} />

    <div className="container-big">
      <Separator size="xl"/>

      <CourseProgram program={props.course.program}  />

      <Separator size="md"/>

    </div>

    <div className="container-full">
      <SeparatorWave bg1={theme.separatorColor1} bg2={theme.separatorColor2} />
    </div>

    {/*Course Mode*/}
    {
      props.course.mode ?
        <div className="container-big">

          <Separator size="xl"/>

          <CourseMode {...props} mode={props.course.mode} />
          <br/>
          <br/>
          <br/>
        </div> : null
    }

    {/*Example Lesson*/}
    <CourseLessonExample lesson={props.course.exampleLesson}/>

    {/*Profile*/}
    {
      !props.course.instructors &&   <div className="container-big">
        <CourseInstructorProfile theme={theme} {...props}/>
      </div>
    }
    {
      props.course.instructors &&   <div className="container-big">
        {
          props.course.instructors.map((item, index) => {
            const Compo = item;
            return <div key={index}><Compo /></div>
          })
        }
      </div>
    }



    {/*Review*/}
    {
      carousels.reviews ?
        <div className="container-big">
          <Separator size="xl"/>

          <CarouselCarouselReview
            {...props}
            items={carousels.reviews}
          />
        </div>: null
    }

    {/*Pricing table*/}
    <div className="container-full">
      <SeparatorWave bg1={theme.separatorColor1} bg2={theme.separatorColor2} />
    </div>

    <div style={{paddingTop: '3rem'}} id="pricingPanel">
      <CoursePriceSingle
        {...props}
        pricing={pricing}
        theme={theme}
      />
    </div>


    {/*FAQ*/}
    {
      faq ? <div className="container-fluid" style={{ borderTop: '1px solid grey'}}>
        <Separator size="xl"/>

        <div className="container-big">
          <CourseFaq faq={faq} theme={theme}></CourseFaq>
        </div>
      </div> : null
    }


    {/*Newsletter*/}
    <div id="newsletter">
      <CourseNewsletterLink
        newsletter={newsletter}
        theme={theme}
      />
    </div>

     <Footer visible={theme.footerVisible}/>

    <ArrowScrollToTop color={theme.iconColor2} />


    {/*<Link to="/">Go back to the homepages</Link>*/}


  </Layout>
}
