/* eslint-disable */

import React, { useState } from "react"
import css from './CourseNewsletter.module.css';
import cn from 'classnames';
import { faCheck } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import MailchimpSubscribe from "react-mailchimp-subscribe"
import { Separator } from "../course-ui-components/SeparatorPadding"

// const url = "//fabio_biondi.us16.list-manage.com/subscribe/post?u=2478e04b9171b207e77c6cbc0&id=85573d8b21";
/**
 * NOTE: questo url si trova cliccando su SIGNUP FORMS e EMBEDDED FORMS
 * https://fabiobiondi.us16.list-manage.com/subscribe/post?u=2478e04b9171b207e77c6cbc0&amp;id=85573d8b21
 * @type {string}
 */
// const url = "https://fabiobiondi.us16.list-manage.com/subscribe/post?u=2478e04b9171b207e77c6cbc0&amp;id=85573d8b21";

export const CourseNewsletterLink = (props) => (
  props.newsletter.enabled ? <Compo {...props}></Compo> : null
)


const Compo = ({ newsletter, theme }) => {
  const submit = () => {
    // location.href = 'https://formazione.fabiobiondi.io/_html/newsletter.html'
    //window.open('https://fabiobiondi.us16.list-manage.com/subscribe/post?u=2478e04b9171b207e77c6cbc0&id=9c1aa5a662')
    window.open('https://training.fabiobiondi.io/newsletter')
  }
  return (
    <div className={cn('container-full', css.wrapper)} style={{backgroundColor: theme.panelBackground1}}>
      <div className={cn('container-big', 'center', css.newsletter_box)} style={{background: 'white'}}>
        <div className="text-paragraph-title">
          ISCRIVITI ALLA NEWSLETTER
        </div>

        <Separator size="md"/>
        <div>Per ricevere articoli, video tutorial e rimanere aggiornati su corsi, eventi e iniziative</div>
        <Separator size="lg"/>


        <div style={{padding: '0 30px'}}>
          {/*<div className={css.InputAddOn}>
            <input className="form-control text horizontal"
                   style={{ maxWidth: '80%'}}
                   placeholder="La tua email"/>
            <button className={cn('button', 'button-round-right')}>

              <FontAwesomeIcon icon={faCheck} />
              <span className="hide-on-mobile"> ISCRIVITI</span>
            </button>
          </div>
  */}
          {/*<MailchimpSubscribe url={url}/>*/}
          <button
            onClick={submit}
            className={cn('button button1')}
            style={{
              backgroundColor: theme.buttonColor,
            }}
          >ISCRIVITI</button>



        </div>

      </div>
    </div>
)}
