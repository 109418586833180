import cn from "classnames"
import React from "react"
import css from './CourseList.module.css';
import { Link } from "@reach/router"
import { faAngleRight } from "@fortawesome/free-solid-svg-icons"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Separator } from "../course-ui-components/SeparatorPadding"
import { goto } from "../../utils/links"

export const CoursesList = ({ opened }) => {
  return (
    <div className={cn('container-big', css.wrapper, {[css.closed]: !opened})}>
      <div className="row">
        <div className="col-xs-12 col-md-4">
          <Separator size="md" />
          <div className="text-paragraph-subtitle bold">CORSI PROPEDEUTICI</div>
          <Separator size="xs" />

          <div>
            <FontAwesomeIcon icon={faAngleRight} fixedWidth={true} style={{marginRight: 10}} />
            <Link to="corso-javascript-typescript/">JAVASCRIPT ES6 & TYPESCRIPT INTRO</Link>
          </div>

          <Separator size="md" />
          <div className="text-paragraph-subtitle bold">CORSI REACT</div>
          <Separator size="xs" />
          <div>
            <FontAwesomeIcon icon={faAngleRight} fixedWidth={true} style={{marginRight: 10}} />
            <Link to="corso-react-typescript">REACT & TYPESCRIPT</Link>
          </div>

          <div>
            <FontAwesomeIcon icon={faAngleRight} fixedWidth={true} style={{marginRight: 10}} />
            <Link to="corso-react-redux-typescript">REACT/REDUX & TYPESCRIPT</Link>
          </div>



          {/*<div>
              <FontAwesomeIcon icon={faAngleRight} fixedWidth={true} style={{marginRight: 10}} />
              <Link to="corso-react-core-concepts">ES6: REACT CORE CONCEPTS</Link>
            </div>
            <div>
              <FontAwesomeIcon icon={faAngleRight} fixedWidth={true} style={{marginRight: 10}} />
              <Link to="corso-react-mastering">ES6: MASTERING REACT</Link>
            </div>
            <div>
              <FontAwesomeIcon icon={faAngleRight} fixedWidth={true} style={{marginRight: 10}} />
              <Link to="corso-react-redux">ES6: REACT / REDUX</Link>
            </div>*/}
        </div>
        <div className="col-xs-12 col-md-4">
          <Separator size="md" />
          <div className="text-paragraph-subtitle bold">
              CORSI ANGULAR
          </div>
          <Separator size="xs" />

            {/* <div>
              <FontAwesomeIcon icon={faAngleRight} fixedWidth={true} style={{marginRight: 10}} />
              <Link to="corso-javascript-typescript">JAVASCRIPT ES6/TYPESCRIPT</Link>
            </div>*/}
            <div>
              <FontAwesomeIcon icon={faAngleRight} fixedWidth={true} style={{marginRight: 10}} />
              <Link to="corso-angular-core-concepts">ANGULAR CORE CONCEPTS</Link>
            </div>
            <div>
              <FontAwesomeIcon icon={faAngleRight} fixedWidth={true} style={{marginRight: 10}} />
              <Link to="corso-angular-mastering">MASTERING ANGULAR</Link>
            </div>
            <div>
              <FontAwesomeIcon icon={faAngleRight} fixedWidth={true} style={{marginRight: 10}} />
              <Link to="corso-angular-ui">ANGULAR MASTERING UI</Link>
            </div>
          <div>
            <FontAwesomeIcon icon={faAngleRight} fixedWidth={true} style={{marginRight: 10}} />
            <Link to="corso-angular-and-rxjs">ANGULAR & RXJS</Link>
          </div>
            <div>
              <FontAwesomeIcon icon={faAngleRight} fixedWidth={true} style={{marginRight: 10}} />
              <Link to="corso-angular-reactive-forms">MASTERING REACTIVE FORMS</Link>
            </div>
            <div>
              <FontAwesomeIcon icon={faAngleRight} fixedWidth={true} style={{marginRight: 10}} />
              <Link to="corso-angular-ngrx">ANGULAR, REDUX & NGRX</Link>
            </div>
        </div>



        <div className="col-xs-12 col-md-4">
          <Separator size="md" />
          <div className="text-paragraph-subtitle bold">VIDEO CORSI</div>
          <Separator size="xs" />
          <div >
              <FontAwesomeIcon icon={faAngleRight} fixedWidth={true} style={{marginRight: 10}} />
              {/*<Link to="videocorso-angular-fundamentals">ANGULAR FUNDAMENTALS</Link>*/}
            <a onClick={() => goto('https://www.angularfundamentals.com', '_blank')} className="pointer">ANGULAR FUNDAMENTALS</a>
          </div>
          <div >
            <FontAwesomeIcon icon={faAngleRight} fixedWidth={true} style={{marginRight: 10}} />
            <a onClick={() => goto('http://www.ngrx.it', '_blank')} className="pointer">ANGULAR, RxJS & NGRX</a>
          </div>
          <br/>
          <div >
            <FontAwesomeIcon icon={faAngleRight} fixedWidth={true} style={{marginRight: 10}} />
            <a onClick={() => goto('https://videocorsi.fabiobiondi.io/corsi/git-fundamentals', '_blank')} className="pointer">GIT & GITHUB FUNDAMENTALS</a>
          </div>
          <div >
            <FontAwesomeIcon icon={faAngleRight} fixedWidth={true} style={{marginRight: 10}} />
            <a onClick={() => goto('https://videocorsi.fabiobiondi.io/corsi/next-fundamentals', '_blank')} className="pointer">NEXTJS e TYPESCRIPT</a>
          </div>

        </div>

      </div>
    </div>
  )
}
