import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import css from './CourseSummaryTable.module.css';

export const CourseSummaryTable = ({theme, data}) => (
  <>
    {
      data.map((item, i)=> {
        return (
          <div className={'row ' + css.item} key={i}>
            <div className="col-xs-2" style={{fontSize: '1.2rem'}}>
              <FontAwesomeIcon
                icon={item.icon} fixedWidth={true}
                style={{width: 20, fontSize: 20, color: theme.iconColor1}} />
            </div>

            <div className="col-xs-10 right" style={{fontSize: '1.1rem'}}>
              {item.label} <strong>{item.value}</strong>
            </div>
          </div>
        )
      })
    }
  </>
)
