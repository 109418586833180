import React from 'react';
import css from './CourseInstructorProfile.module.css';
import { graphql, StaticQuery } from "gatsby"
import { Separator } from "../course-ui-components/SeparatorPadding"
import { goto } from "../../utils/links"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons"


export class CourseInstructorProfileGiorgioBoa extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            profile_giorgio_boa: file(relativePath: { eq: "courses/_general/instructors/profile-giorgio-boa.png" }) { ...thumb },
            loghi_gde_mvp: file(relativePath: { eq: "courses/_general/profile/loghi_gde_mvp.png" }) { ...thumb },
            
          }
        `}
        render={data => <Component {...this.props} data={data}  /> }
      />
    )
  }
}
/*angular_badge: file(relativePath: { eq: "courses/_general/profile/2019-GDE-Angular-Badge.png" }) { ...thumb },
web_badge: file(relativePath: { eq: "courses/_general/profile/2019-GDE-Web-Technologies-Badge.png" }) { ...thumb },*/

export const Component = props => (

  <div className={css.wrapper}>
    <div className="text-box-title center">
      <span className="light">ISTRUTTORE: </span>
      <br className="show-on-xs"/>
      <span className="bold">GIORGIO BOA</span>
    </div>

    <br/>

    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-5">
        <img
          width="100%"
          src={props.data.profile_giorgio_boa.childImageSharp.fluid.src}
          alt={props.data.profile_giorgio_boa.name}/>

        <br/>
        <br/>

        <div className="center">
          <button
            style={{backgroundColor: props.theme && props.theme.buttonColor}}
            className="button button1"
            onClick={() => goto('https://gioboa.github.io/', '_blank', null)}
          >
            <FontAwesomeIcon icon={faArrowCircleRight} fixedWidth={true} style={{marginRight: 10}} />
           gioboa.github.io
          </button>
        </div>
        <br/>
        <br/>
      </div>

      <div className="col-xs-12 col-md-7">

        <div className="text-paragraph-title">
          FULL STACK DEVELOPER
        </div>
        <Separator size="md"/>
        <div>

          Giorgio Boa, fullstack developer con più di 12 anni di esperienza tra applicazioni enterprise per piattaforme desktop, web e mobile native.
          <br/>
          <br/>
          Speaker in svariati eventi italiani, mi occupo di argomenti che riguardano il modo Front-End, DevOps e architetture applicative.
          <br/><br/>
          Attualmente fullstack developer in Targa Telematics, offro  consulenza e  attività di formazione in ambito Git e DevOps.

          <br/>



        </div>
      </div>

    </div>
  </div>
)
