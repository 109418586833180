import { Link } from "gatsby"
import PropTypes from "prop-types"
import React,{ useState } from "react"
import { CoursesList } from "./CourseList"

import { faBars, faCode, faEnvelope } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { goto } from "../../utils/links"

const CoursesMenuCollapsable = ({ siteTitle }) => {
  const [opened, setOpened] = useState(false);
  return (
    <header >
      <div className="container-big" >
        <div className="row" style={{ padding: '10px 0 0px 0', }}>
          <div className="col-xs-5" >
            <Link to="/" style={{ textDecoration: `none`, fontSize: 30, color: 'black' }}>
              {/*{siteTitle}*/}
              <div className="text-box-title">
                <span className="light">F</span><span className="light hide-on-mobile">ABIO</span><span className="bold">BIONDI</span>
                {/*<div className="light" style={{fontSize: 11}}>FRONTEND <span className="bold">TRAINING</span></div>*/}
                <div  style={{fontSize: 15, marginTop: -7, marginLeft: 3, lineHeight: '15px'}}>GOOGLE EXPERT <br className="show-on-xs"/> & MICROSOFT MVP</div>
              </div>
            </Link>
          </div>

          <div
            className="col-xs-7 right pointer"
            style={{marginTop: 15}}
          >

            <span onClick={() => goto('https://training.fabiobiondi.io', '_blank')}>
              <FontAwesomeIcon icon={faCode} fixedWidth={true} style={{width: 20, fontSize: 20}} />
              <span className="hide-on-mobile"> Blog</span>
            </span>
            <span style={{margin: '0 5px'}}> | </span>

            <span onClick={() => goto('/forms/contact-form', '_self')}>
              <FontAwesomeIcon icon={faEnvelope} fixedWidth={true} style={{width: 20, fontSize: 20}} />
              <span className="hide-on-mobile"> Contattami</span>
            </span>
            <span style={{margin: '0 5px'}}> | </span>
            <span onClick={() => setOpened(!opened)}>
              <FontAwesomeIcon icon={faBars} fixedWidth={true} style={{width: 20, fontSize: 20}} />
              <span className="hide-on-mobile"> Elenco corsi</span>
            </span>

          </div>
        </div>
      </div>

      <CoursesList opened={opened}></CoursesList>


    </header>
)}

CoursesMenuCollapsable.propTypes = {
  siteTitle: PropTypes.string,
}

CoursesMenuCollapsable.defaultProps = {
  siteTitle: ``,
}

export default CoursesMenuCollapsable
