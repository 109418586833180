import React from 'react';
import css from './Footer.module.css';
import cn from 'classnames';
import { faCaretRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"


export const Footer = ({visible}) => {
  return visible ? <FooterContent /> : null
}
export const FooterContent = () => {
  return (
    <div className={cn('container-fluid', css.wrapper)}>

      <div className="container-big">
        <div className="row">
          <div className={cn('col-xs-12 col-sm-4', css.col)}>
            <div className={css.title}>
              <span className="light">FABIO</span><span className="bold">BIONDI</span>
            </div>
            <div>Trieste (Italy)</div>
            <div>P.I. <span>01116230317</span> - <a target="_blank" rel="noopener noreferrer" href="https://www.iubenda.com/privacy-policy/57523404">Privacy Policy</a></div>

            <div className={css.separator}></div>
            <div><FontAwesomeIcon icon={faCaretRight} fixedWidth={true} style={{width: 20, fontSize: 20}} /> <a target="_blank" rel="noopener noreferrer" href="http://www.fabiobiondi.io">fabiobiondi.io</a></div>
            {/*<div><FontAwesomeIcon icon={faCaretRight} fixedWidth={true} style={{width: 20, fontSize: 20}} /> <a target="_blank" rel="noopener noreferrer" href="http://training.fabiobiondi.io">Tech Blog</a></div>*/}
          </div>
          <div className={cn('col-xs-12 col-sm-4', css.col)}>
            <div className={css.title}>Follow me on...</div>
            <div><FontAwesomeIcon icon={faCaretRight} fixedWidth={true} style={{width: 20, fontSize: 20}} /> <a rel="noopener noreferrer" href="https://www.linkedin.com/in/fabiobiondi/" target="_blank">LinkedIn</a></div>
            <div><FontAwesomeIcon icon={faCaretRight} fixedWidth={true} style={{width: 20, fontSize: 20}} /> <a rel="noopener noreferrer" href="https://www.youtube.com/user/BiondiFabio" target="_blank">YouTube Channel</a></div>
            <div><FontAwesomeIcon icon={faCaretRight} fixedWidth={true} style={{width: 20, fontSize: 20}} /> <a rel="noopener noreferrer" href="https://www.twitch.tv/fabio_biondi" target="_blank">Twitch</a></div>
            <div><FontAwesomeIcon icon={faCaretRight} fixedWidth={true} style={{width: 20, fontSize: 20}} /> <a rel="noopener noreferrer" href="https://www.facebook.com/groups/fabiobiondi.training" target="_blank">Facebook</a></div>
            <div><FontAwesomeIcon icon={faCaretRight} fixedWidth={true} style={{width: 20, fontSize: 20}} /> <a rel="noopener noreferrer" href="https://www.instagram.com/biondifabio/" target="_blank">Instagram</a></div>
            <div><FontAwesomeIcon icon={faCaretRight} fixedWidth={true} style={{width: 20, fontSize: 20}} /> <a rel="noopener noreferrer" href="https://twitter.com/biondifabio" target="_blank">Twitter</a></div>
          </div>
          <div className={cn('col-xs-12 col-sm-4', css.col)}>
            <div className={css.title}>Facebook Groups</div>
            <div><FontAwesomeIcon icon={faCaretRight} fixedWidth={true} style={{width: 20, fontSize: 20}} /> <a rel="noopener noreferrer" href="https://www.facebook.com/groups/angularjs.developer.italiani" target="_blank">Angular Developers Italiani</a></div>
            <div><FontAwesomeIcon icon={faCaretRight} fixedWidth={true} style={{width: 20, fontSize: 20}} /> <a rel="noopener noreferrer" href="https://www.facebook.com/groups/react.developer.italiani/" target="_blank">React Developers Italiani</a></div>
            <div><FontAwesomeIcon icon={faCaretRight} fixedWidth={true} style={{width: 20, fontSize: 20}} /> <a rel="noopener noreferrer" href="https://www.facebook.com/groups/javascript.developer.italiani" target="_blank">Javascript Developers Italiani</a></div>
            <div><FontAwesomeIcon icon={faCaretRight} fixedWidth={true} style={{width: 20, fontSize: 20}} /> <a rel="noopener noreferrer" href="https://www.facebook.com/groups/opportunita.developer.italiani/" target="_blank">Opportunità Devs Italiani</a></div>
          </div>
        </div>
      </div>
    </div>
  )
};

